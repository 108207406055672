











































































import { Component } from "vue-property-decorator";
import { translate } from "../../plugins/i18n";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "../../components/Footer.vue";
import Request from "../../services/request";
import env from "../../services/env";

@Component({
  components: {
    FadeTransition,
    ContentFooter,
  },
})
export default class AuthFlow extends Request {
  loading: boolean = false;
  email: string = "";
  params: any = {};
  logoSrc = require("@/assets/LOGO-heppy-2020-WHITE (2).png");
  async oAuthMatrix() {
    const result = await this.get(
      this.paths.authFlowPath + `?email=${this.email}&scope=HEP`
    );
    if (result) {
      window.location.href = `${env.protocol}${env.serviceDiscovery}${this.paths.customAuthPath}${this.params.code}&state=${this.params.state}`;
    }
  }
  onEnter() {
    this.oAuthMatrix();
  }
  headerTitle() {
    return `${translate("authFlow")}`;
  }

  mounted() {
    this.logoSrc = require("@/assets/LOGO-heppy-2020-WHITE (2).png");
    const uri = window.location.href.split("?");
    if (uri.length == 2) {
      const vars = uri[1].split("&");
      let tmp: any = [];
      vars.forEach((v) => {
        tmp = v.split("=");
        if (tmp.length == 2) this.params[tmp[0]] = tmp[1];
      });
    }
    console.log(this.params);
  }
  onPressEnter() {
    this.oAuthMatrix();
  }
}
