











































































import { Component } from "vue-property-decorator";
import { translate } from "../../plugins/i18n";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "../../components/Footer.vue";
import Request from "../../services/request";

@Component({
  components: {
    FadeTransition,
    ContentFooter,
  },
})
export default class ForgotPasswordHeppy extends Request {
  loading: boolean = false;
  email: string = "";
  logoSrc = require("@/assets/LOGO-heppy-2020-WHITE (2).png");
  async forgotPassword() {

  await this.get(this.paths.forgotPasswordPath + "?email=" + this.email);

  return  this.$router.push('auth/login'); 
  }
  onEnter() {
    this.forgotPassword();
  }
  headerTitle() {
    return `${translate("forgotPassword")}`;
  }

  mounted() {
    this.logoSrc = require("@/assets/LOGO-heppy-2020-WHITE (2).png");
  }
  onPressEnter() {
    this.forgotPassword();
  }
}
