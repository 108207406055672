





















import { Component } from "vue-property-decorator";
import Request, { setTokenHeader } from "../../services/request";
import { paths } from "../../services/apiPaths";
import { translate } from "../../plugins/i18n";
import LoginHeppy from "@matrixbrasil/matrix-lib-auth-frontend/src/components/loginHeppy/LoginHeppy.vue";
import { mapActions } from "vuex";
import { EventBus } from "../../services/eventBus";
import {
  IValidationRule,
  IUser,
  ISetLogin,
  IPermissions,
} from "../../types/User";
@Component({
  components: { LoginHeppy },
  methods: {
    ...mapActions({
      setPermissions: "auth/setPermissions",
      setLogin: "auth/setLogin",
      setUser: "auth/setUser",
      setUserAgentId: "general/setUserAgentId",
      setSecret: "auth/setSecret",
    }),
  },
})
export default class FullLogin extends Request {
  setUser!: (user: IUser) => void;
  setPermissions!: (permissions: IPermissions[]) => void;
  setLogin!: (user: ISetLogin) => void;
  setSecret!: (isSecretTemporary: boolean) => boolean;
  setUserAgentId!: (userAgentId: number) => number;
  forgotPasswordText = `${translate("forgotPassword")}`;
  emailText = `${translate("email")}`;
  passwordLabelText = `${translate("password")}`;
  signInButtonText = `${translate("button.enter")}`;
  rememberMeText = `${translate("rememberMe")}`;
  path = "";
  scope = "HEP";
  grant_type = "password";
  logo = require("@/assets/MATRIX_LOGO_WHITE.png");
  images = [
    {
      src: require("@/assets/backgroundRed.jpg"),
    },
  ];
  loading: boolean = false;
  logoSrc: string = require("@/assets/LOGO-heppy-2020-WHITE (2).png");
  checkbox: boolean = false;
  client_id: string = "";
  password: string = "";
  client_secret: string = "";
  // params: any = {};
  show1: boolean = false;

  passwordRules: IValidationRule[] = [
    (v: string) => !!v || translate("passwordIsRequired"),
    (v: string) => (v && v.length <= 20) || translate("passwordSize"),
  ];

  emailRules: IValidationRule[] = [
    (v: string) => !!v || translate("emailIsRequired"),
    (v: string) => /.+@.+\..+/.test(v) || translate("validEmail"),
  ];

  rememberUser() {
    if (this.checkbox == true) {
      localStorage.client_id = this.client_id;
      localStorage.password = this.password;
    } else {
      localStorage.client_id = "";
      localStorage.password = "";
    }
  }
  getClientId(value) {
    this.client_id = value;
  }
  getPassword(value) {
    this.password = value;
  }
  created() {
    EventBus.$on("endLoading", (value) => {
      this.loading = value;
    });
    // const uri = window.location.href.split("?");
    // if (uri.length == 2) {
    //   const vars = uri[1].split("&");
    //   let tmp: any = [];
    //   vars.forEach((v) => {
    //     tmp = v.split("=");
    //     if (tmp.length == 2) this.params[tmp[0]] = tmp[1];
    //   });
    // }
  }
  async signIn() {
    this.loading = true;
    return await this.post(this.path, {
      client_id: this.client_id,
      password: this.password,
      scope: this.scope,
      grant_type: this.grant_type,
      client_secret: this.password,
      username: this.client_id,
    }).then(async (data) => {
      this.setUser(data.user);
      this.setSecret(data.isSecretTemporary);
      this.setLogin({ user: data.user, token: data.access_token });
      setTokenHeader(data.access_token);
      const userId = data.user.id;
      const userAgentId = data?.user?.userAgent?.id;
      userAgentId ? this.setUserAgentId(userAgentId) : null;

      await this.get(paths.rolePath + userId).then((data) => {
        localStorage.setItem("role", data.user.id);
        this.setPermissions(data.permissions);
        this.$router.push("/");
      });
    });
  }
  async mounted() {
    this.path = paths.loginPath;
    if (localStorage.client_id) {
      this.client_id = localStorage.client_id;
    }
    if (localStorage.password) {
      this.password = localStorage.password;
    }

    if (localStorage.client_id && localStorage.password) {
      this.checkbox = true;
    }
  }
}
